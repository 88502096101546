import React, {useEffect, useState} from 'react';
import {Input, List, message, Popconfirm, Space} from "antd";
import {delete_filter} from "../../data/data";
import {DeleteOutlined} from "@ant-design/icons";
import {Constants} from "../../data/Constants";


const SavedFilterSidebar = (props) => {
    const [children, setChildren] = useState(Constants.STATE_EMPTY_LIST);
    const [name, setName] = useState(Constants.STATE_EMPTY_STRING);

    useEffect(() => {
        let filteredFilters = props.savedFilters.filter(flt => flt.name.toLowerCase().includes(name.toLowerCase()))
        setChildren(filteredFilters);
    }, [name])

    useEffect(() => {
        props.savedFilters.sort((a, b) => {
            a = a.name.toLowerCase();
            b = b.name.toLowerCase();
            return (a > b) ? 1 : ((b > a) ? -1 : 0)
        });
        setChildren(props.savedFilters);
    }, [props.savedFilters]);

    const deleteFilter = (name) => {
        delete_filter(name, props.user.id, props.setSavedFilters);
    }

    return (
        <Space size="large" style={{padding: 20}} direction="vertical">
            <Input placeholder={Constants.NAME} onChange={text => setName(text.target.value)}/>
            <List
                dataSource={children}
                renderItem={(value) => (
                    <div style={{margin: "5px 10px"}}>
                        <List.Item
                            key={value.name}
                            actions={[
                                <Popconfirm placement="right" title={Constants.ARE_YOU_SURE} okText={Constants.YES} cancelText={Constants.NO}
                                            onConfirm={() => deleteFilter(value.name)}>
                                    <DeleteOutlined/>
                                </Popconfirm>]}
                        >
                            <Popconfirm placement="bottomLeft" title={Constants.ARE_YOU_SURE} okText={Constants.YES} cancelText={Constants.NO}
                                        onConfirm={() => {
                                            message.success(Constants.FILTER_IS_SET);
                                            props.setFilter(value.data);
                                        }}>
                                <List.Item.Meta title={<a>{value.name}</a>}/>
                            </Popconfirm>
                        </List.Item>
                    </div>
                )}
            >
            </List>
        </Space>
    );
}

export default SavedFilterSidebar;
