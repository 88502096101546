import React, { useState, useEffect } from "react";
import { CarColumns, Constants } from "../data/Constants";
import { loadDataForProgressBars } from "../data/data";
import { Space, Progress } from "antd";

const ProgressBars = (props) => {
  const [data, setData] = useState(Constants.STATE_EMPTY_LIST);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetch(props.params);
  }, [props.filter]);

  const fetch = (params = {}) => {
    loadDataForProgressBars(props.filter, setData, setLoading);
  };
  console.log("dat", data);
  return (
    <>
      <Space size="large" style={{ padding: 20 }} direction="vertical">
        {Object.entries(data)
          .sort((a, b) => b[1] - a[1])
          .map(([key, value]) => (
            <div>
              <h4>{CarColumns.COLUMN_TO_NAME[key]}</h4>
              <Progress percent={value || 0} />
            </div>
          ))}
      </Space>
    </>
  );
};

export default ProgressBars;
