import moment from "moment";
import Numeral from "numeral";
import {Constants} from "./data/Constants";

function numberWithSeperator(value, sep) {
    return Numeral(value).format('0,0').replace(/,/g, sep);
}

export function getFullName(user) {
    return user[Constants.FIRST_NAME] + ' ' + user[Constants.LAST_NAME];
}

function getFileNameWithExtension(name, extension) {
    let now = moment();
    let today = now.format("YYYYMMDD_HHmmss");
    return name + "_" + today + "." + extension
}

function createCSVFile(data, fileName) {
    let csv = "";
    if (data) {
        csv += Object.keys(data[0]).join(",") + "\n";
        data.forEach(car => csv += Object.values(car).join(",") + "\n");
    }

    let csvData = new Blob([csv], {type: 'text/csv'});
    let csvUrl = URL.createObjectURL(csvData);

    let hiddenElement = document.createElement('a');
    hiddenElement.href = csvUrl;
    hiddenElement.target = '_blank';
    hiddenElement.download = fileName;
    hiddenElement.click();
}

let exportFunctions = {
    createCSVFile, numberWithSeperator, getFileNameWithExtension, getFullName
}

export default exportFunctions;
