import React, {useEffect} from "react";
import {Button, Checkbox, Form, Input} from "antd";
import {LockOutlined, MailOutlined, UserOutlined} from "@ant-design/icons";
import {Redirect, useHistory, useParams} from "react-router-dom";
import {Constants} from "../data/Constants";

const UserPage = (props) => {
    const layout = {
        labelCol: {span: 8},
        wrapperCol: {span: 16},
    };
    const tailLayout = {
        wrapperCol: {offset: 8, span: 16},
    };
    let history = useHistory();
    let {email} = useParams();

    useEffect(() => {
        props.initialize(email);
    }, [])

    if (!props.user[Constants.ADMIN] && props.user[Constants.EMAIL] !== email) {
        return <Redirect to="/carsale"/>;
    }

    return (
        <Form
            {...layout}
            preserve={true}
            form={props.form}
            name="normal_login"
            className="login-form"
            style={{padding: 20, position: "absolute", width: "50%", left: "25%", top: "25%"}}
            onFinish={values => {
                props.onClick(values)(history)
            }}
        >
            <Form.Item
                name={Constants.ID}
                label="Id"
                hidden={true}
                rules={[
                    {
                        required: !props.newUser,
                        message: Constants.INPUT_ID
                    }
                ]}
            >
                <Input placeholder="Id"/>
            </Form.Item>
            {props.user[Constants.ADMIN] ? <Form.Item
                name={Constants.ADMIN}
                label="Admin"
                valuePropName="checked"
            >
                <Checkbox/>
            </Form.Item> : undefined}
            <Form.Item
                name={Constants.USERNAME}
                label="Username"
                rules={[{required: true, message: Constants.INPUT_USERNAME}]}
            >
                <Input prefix={<UserOutlined className="site-form-item-icon"/>} placeholder="Username"/>
            </Form.Item>
            <Form.Item
                name={Constants.FIRST_NAME}
                label="First Name"
                rules={[{required: true, message: Constants.INPUT_FIRST_NAME}]}
            >
                <Input placeholder="First Name"/>
            </Form.Item>
            <Form.Item
                name={Constants.LAST_NAME}
                label="Last Name"
                rules={[{required: true, message: Constants.INPUT_LAST_NAME}]}
            >
                <Input placeholder="Last Name"/>
            </Form.Item>
            <Form.Item
                name={Constants.EMAIL}
                label="E-mail"
                rules={[
                    {
                        type: 'email',
                        message: Constants.INVALID_EMAIL,
                    },
                    {
                        required: true,
                        message: Constants.INPUT_EMAIL,
                    },
                ]}
            >
                <Input prefix={<MailOutlined className="site-form-item-icon"/>} placeholder="E-mail"/>
            </Form.Item>
            <Form.Item
                name={Constants.PASSWORD}
                label="New Password"
                rules={[{required: props.newUser}]}
            >
                <Input
                    prefix={<LockOutlined className="site-form-item-icon"/>}
                    type="password"
                    placeholder="Password"
                />
            </Form.Item>
            <Form.Item
                name={Constants.CONFIRM_PASSWORD}
                label="Confirm New Password"
                rules={[{required: props.newUser}]}
            >
                <Input
                    prefix={<LockOutlined className="site-form-item-icon"/>}
                    type="password"
                    placeholder="Password"
                />
            </Form.Item>
            <Form.Item
                name={Constants.ACTIVE}
                label="Active"
                hidden={!props.user[Constants.ADMIN]}
                valuePropName="checked"
            >
                <Checkbox/>
            </Form.Item>
            <Form.Item label=" " colon={false}>
                <Button {...tailLayout} type="primary" htmlType="submit" className="login-form-button">
                    {props.btnText}
                </Button>
            </Form.Item>
        </Form>
    );
}


export default UserPage;