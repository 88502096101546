import React from "react";
import {CarColumns, TraderColumns} from "../data/Constants";
import CustomTraderTable from "../customComponents/CustomTraderTable";
import CustomPieChart from "../customComponents/CustomPieChart";
import {loadDataForPieChart} from "../data/data";

const TradersAndPieChart = (props) => {

    const params_trader_kind = {
        columnName: CarColumns.TRADER_KIND,
    }

    return (
        <>
            <div className="space">
                <div style={{width: "50%"}}>
                    <CustomTraderTable
                        filter={props.filter}
                        columnName={TraderColumns.TRADER}
                        name="Traders"/>
                </div>
                <div style={{width: "50%"}}>
                    <CustomPieChart
                        loadData={(setData, setLoading) => loadDataForPieChart(props.filter, params_trader_kind, setData, setLoading)}
                        position="right"
                        filter={props.filter}
                        columnName={CarColumns.TRADER_KIND}
                        name="Traders"/>
                </div>
            </div>
        </>
    );

}

export default TradersAndPieChart;