import React, {useEffect, useState} from 'react';
import {Pie} from '@ant-design/charts';
import {Constants} from "../data/Constants";
import {Spin} from "antd";

const CustomPieChart = (props) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetch(props.params)
    }, [props.filter])

    const fetch = (params = {}) => {
        props.loadData(setData, setLoading);
    }

    let config = {
        appendPadding: 10,
        data: data,
        angleField: Constants.COUNT,
        colorField: props.columnName,
        radius: 0.8,
        label: {type: 'outer'},
        interactions: [{type: 'element-active'}],
        legend: {
            position: props.position,
        },
    };

    return (
        <div style={{display: "flex", flexDirection: "column", height: "100%"}}>
            <Spin spinning={loading}>
                <h2 style={{textAlign: "center"}}>{props.name}</h2>
                <Pie style={{width: "100%", height: "100%"}} {...config}/>
            </Spin>
        </div>
    );
};
export default CustomPieChart;