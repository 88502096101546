import {Input} from "antd";
import React from "react";

const InputBox = (props) => {

    return (
        <Input placeholder={props.placeholder} onChange={props.onChange}/>
    );

}

export default InputBox;