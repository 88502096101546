import React from "react";
import CustomCarTable from "../customComponents/CustomCarTable";
import {CarColumns} from "../data/Constants";

const CarTables = (props) => {

    return (
        <>
            <h2>Official Traders</h2>
            <CustomCarTable
                filter={props.filter}
                tableParams={{
                    [CarColumns.TABLE_OFFICIAL_TRADER]: true,
                    [CarColumns.TABLE_PROFESSIONAL_TRADER]: true
                }}
            />
            <h2>Other Traders</h2>
            <CustomCarTable
                filter={props.filter}
                tableParams={{
                    [CarColumns.TABLE_OFFICIAL_TRADER]: false,
                    [CarColumns.TABLE_PROFESSIONAL_TRADER]: true
                }}
            />
            <h2>Private</h2>
            <CustomCarTable
                filter={props.filter}
                tableParams={{
                    [CarColumns.TABLE_OFFICIAL_TRADER]: false,
                    [CarColumns.TABLE_PROFESSIONAL_TRADER]: false
                }}
            />
        </>
    );

}

export default CarTables;