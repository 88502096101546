import {Select} from "antd";

const {Option} = Select;

const TriStateFilterWithFilter = (props) => {

    const children = [];
    Object.entries(props.items).forEach(([key, item]) => children.push(<Option key={item} value={item}>{key}</Option>))

    return (
        <Select
            value={props.chosen}
            style={{width: '100%'}}
            placeholder={props.extra}
            onChange={value => props.onChange(value)}
            defaultValue={props.defaultValue}
            allowClear
        >
            {children}
        </Select>
    );

}

export default TriStateFilterWithFilter;