import React from "react";
import CustomBarChart from "../customComponents/CustomBarChart";
import {CarColumns, Constants} from "../data/Constants";
import CustomPieChart from "../customComponents/CustomPieChart";
import {loadDataForPieChart, loadDataForSegmentBarChart} from "../data/data";

const FuelInteriorPowerCharts = (props) => {

    const params = {
        columnName: CarColumns.POWER_KW,
        segmentSize: Constants.POWER_SEGMENT_SIZE,
        segmentZero: Constants.POWER_SEGMENT_ZERO,
        zero: Constants.POWER_SEGMENT_ZERO_NAME,
        segmentName: CarColumns.POWER_SEGMENT,
    }

    const params_fuel = {
        columnName: CarColumns.FUEL,
    }

    const params_interior = {
        columnName: CarColumns.INTERIOR,
    }

    return (
        <div className="space">
            <div style={{width: "33%"}}>
                <CustomPieChart
                    loadData={(setData, setLoading) => loadDataForPieChart(props.filter, params_fuel, setData, setLoading)}
                    filter={props.filter}
                    params={params_fuel}
                    position="left"
                    columnName={CarColumns.FUEL}
                    name="Fuel types"/>
            </div>
            <div style={{width: "33%"}}>
                <CustomPieChart
                    loadData={(setData, setLoading) => loadDataForPieChart(props.filter, params_interior, setData, setLoading)}
                    position="left"
                    filter={props.filter}
                    params={params_interior}
                    columnName={CarColumns.INTERIOR}
                    name="Interior types"/>
            </div>
            <div style={{width: "33%"}}>
                <CustomBarChart
                    params={params}
                    filter={props.filter}
                    columnName={Constants.TITLE}
                    rowName={Constants.COUNT}
                    name="Power Segments"
                    loadData={(setData, setLoading) => loadDataForSegmentBarChart(props.filter, params, setData, setLoading)}
                    setClicked={value => {
                        props.setFilteredPowerKW(value.min_value, value.max_value);
                    }}/>
            </div>
        </div>
    );

}

export default FuelInteriorPowerCharts;