import React, {useEffect, useState} from 'react';
import {Constants, DataKeys, FilterKeys} from "../../data/Constants"
import {loadDataForFilters, loadDataForModelFilter} from "../../data/data";
import SelectFilterWithFilter from "../../components/SelectFilterWithFilter";
import RangeFilterWithFilter from "../../components/RangeFilterWithFilter";
import TriStateFilterWithFilter from "../../components/TriStateFilterWithFilter";
import {Button, Popconfirm, Space, Spin} from "antd";
import {DeleteOutlined} from "@ant-design/icons";


const Sidebar = (props) => {

    const resetDataForFilters = () => {
        props.setFilter(props.emptyFilter);
    }

    const setFilter = (value, key) => props.setFilter(previousFilter => ({
        ...previousFilter,
        [key]: value
    }))

    return (
        <Spin spinning={props.loading}>
            {props.filter ?
                <Space size="large" style={{padding: 20}} direction="vertical">
                    <Popconfirm placement="right" title="Are you sure？" okText="Yes" cancelText="No"
                                onConfirm={() => resetDataForFilters()}>
                        <Button type="primary" icon={<DeleteOutlined/>}>Reset Filters</Button>
                    </Popconfirm>

                    <div>
                        <h4 className="subtitleSpace">Make</h4>
                        <SelectFilterWithFilter chosen={props.filter[FilterKeys.MAKE]}
                                                onChange={value => setFilter(value, FilterKeys.MAKE)}
                                                items={props.dataForFilters[DataKeys.MAKES]}
                                                mode="single"
                                                extra={Constants.EMPTY_SELECT}/>
                    </div>

                    <div>
                        <h4 className="subtitleSpace">Model</h4>
                        <SelectFilterWithFilter chosen={props.filter[FilterKeys.MODELS]}
                                                onChange={value => setFilter(value, FilterKeys.MODELS)}
                                                items={props.modelData}
                                                mode="multiple"
                                                extra={Constants.ALL}/>
                    </div>

                    <div>
                        <h4 className="subtitleSpace">Age (months)</h4>
                        <RangeFilterWithFilter step={6}
                                               chosen={props.filter[FilterKeys.AGE_RANGE]}
                                               onChange={value => setFilter(value, FilterKeys.AGE_RANGE)}
                                               min={props.dataForFilters[DataKeys.AGE_MIN]}
                                               max={props.dataForFilters[DataKeys.AGE_MAX]}/>
                    </div>

                    <div>
                        <h4 className="subtitleSpace">Sold</h4>
                        <TriStateFilterWithFilter chosen={props.filter[FilterKeys.SOLD]}
                                                  onChange={value => setFilter(value, FilterKeys.SOLD)}
                                                  items={Constants.YES_NO}
                                                  extra={Constants.EMPTY_SELECT}/>
                    </div>

                    <div>
                        <h4 className="subtitleSpace">Mileage</h4>
                        <RangeFilterWithFilter step={1000}
                                               chosen={props.filter[FilterKeys.MILEAGE_RANGE]}
                                               onChange={value => setFilter(value, FilterKeys.MILEAGE_RANGE)}
                                               min={props.dataForFilters[DataKeys.KM_MIN]}
                                               max={props.dataForFilters[DataKeys.KM_MAX]}/>
                    </div>

                    <div>
                        <h4 className="subtitleSpace">Body Type</h4>
                        <SelectFilterWithFilter chosen={props.filter[FilterKeys.BODY_TYPES]}
                                                onChange={value => setFilter(value, FilterKeys.BODY_TYPES)}
                                                items={props.dataForFilters[DataKeys.BODY_TYPES]}
                                                mode="multiple"
                                                extra={Constants.ALL}/>
                    </div>

                    <div>
                        <h4 className="subtitleSpace">Automatic</h4>
                        <TriStateFilterWithFilter chosen={props.filter[FilterKeys.AUTOMATIC]}
                                                  onChange={value => setFilter(value, FilterKeys.AUTOMATIC)}
                                                  items={Constants.YES_NO}
                                                  extra={Constants.EMPTY_SELECT}/>
                    </div>

                    <div>
                        <h4 className="subtitleSpace">Fuel type</h4>
                        <SelectFilterWithFilter chosen={props.filter[FilterKeys.FUEL_TYPES]}
                                                onChange={value => setFilter(value, FilterKeys.FUEL_TYPES)}
                                                items={props.dataForFilters[DataKeys.FUELS]}
                                                mode="multiple"
                                                extra={Constants.ALL}/>
                    </div>

                    <div>
                        <h4 className="subtitleSpace">Engine CC</h4>
                        <RangeFilterWithFilter
                            chosen={props.filter[FilterKeys.ENGINE_CC_RANGE]}
                            onChange={value => {
                                setFilter([value[0], value[1] === Constants.MAX_ENGINE_CC ? props.dataForFilters[DataKeys.ENGINE_CC_MAX] : value[1]], FilterKeys.ENGINE_CC_RANGE);
                            }}
                            min={props.dataForFilters[DataKeys.ENGINE_CC_MIN]}
                            max={Constants.MAX_ENGINE_CC}/>
                    </div>

                    <div>
                        <h4 className="subtitleSpace">Doors</h4>
                        <RangeFilterWithFilter chosen={props.filter[FilterKeys.DOORS_RANGE]}
                                               onChange={value => setFilter(value, FilterKeys.DOORS_RANGE)}
                                               min={props.dataForFilters[DataKeys.DOORS_MIN]}
                                               max={props.dataForFilters[DataKeys.DOORS_MAX]}/>
                    </div>

                    <div>
                        <h4 className="subtitleSpace">Power KW</h4>
                        <RangeFilterWithFilter chosen={props.filter[FilterKeys.POWER_KW_RANGE]}
                                               onChange={value => setFilter(value, FilterKeys.POWER_KW_RANGE)}
                                               min={props.dataForFilters[DataKeys.POWER_KW_MIN]}
                                               max={props.dataForFilters[DataKeys.POWER_KW_MAX]}/>
                    </div>

                    <div>
                        <h4 className="subtitleSpace">Price</h4>
                        <RangeFilterWithFilter
                            chosen={props.filter[FilterKeys.PRICE_RANGE]}
                            onChange={value => {
                                setFilter([value[0], value[1] === Constants.MAX_PRICE ? props.dataForFilters[DataKeys.PRICE_MAX] : value[1]], FilterKeys.PRICE_RANGE);
                            }}
                            min={props.dataForFilters[DataKeys.PRICE_MIN]}
                            max={Constants.MAX_PRICE}/>
                    </div>

                    <div>
                        <h4 className="subtitleSpace">Sold by professional</h4>
                        <TriStateFilterWithFilter chosen={props.filter[FilterKeys.PROFESSIONAL_TRADER]}
                                                  onChange={value => setFilter(value, FilterKeys.PROFESSIONAL_TRADER)}
                                                  items={Constants.YES_NO}
                                                  extra={Constants.EMPTY_SELECT}
                                                  defaultValue="Yes"/>
                    </div>

                    <div>
                        <h4 className="subtitleSpace">Official Trader</h4>
                        <TriStateFilterWithFilter chosen={props.filter[FilterKeys.OFFICIAL_TRADER]}
                                                  onChange={value => setFilter(value, FilterKeys.OFFICIAL_TRADER)}
                                                  items={Constants.YES_NO}
                                                  extra={Constants.EMPTY_SELECT}/>
                    </div>

                    <div>
                        <h4 className="subtitleSpace">Trader</h4>
                        <SelectFilterWithFilter chosen={props.filter[FilterKeys.TRADERS]}
                                                onChange={value => setFilter(value, FilterKeys.TRADERS)}
                                                items={props.dataForFilters[DataKeys.TRADERS]}
                                                mode="multiple"
                                                extra={Constants.ALL}/>
                    </div>

                    <div>
                        <h4 className="subtitleSpace">Region</h4>
                        <SelectFilterWithFilter chosen={props.filter[FilterKeys.REGIONS]}
                                                onChange={value => setFilter(value, FilterKeys.REGIONS)}
                                                items={props.dataForFilters[DataKeys.REGION]}
                                                mode="multiple"
                                                extra={Constants.ALL}/>
                    </div>

                    <br/>
                </Space> : <></>
            }
        </Spin>
    );
}

export default Sidebar;
