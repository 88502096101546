import React, {useEffect, useState} from 'react';
import {Table} from "antd";
import {Constants} from "../data/Constants";


const CustomTable = (props) => {

    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: Constants.PAGE_SIZE,
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let params = {
            pagination: {
                current: 1,
                pageSize: Constants.PAGE_SIZE,
            }
        }
        fetch(params)
    }, [props.filter])

    const handleTableChange = (pagination, filters, sorter) => {
        let params = {
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination
        }
        fetch(params);
    }

    const fetch = (params = {}) => {
        props.loadData(params, setPagination, setData, setLoading);
    }

    return (
        <Table
            columns={props.columns}
            rowKey={props.rowKey}
            dataSource={data}
            pagination={pagination}
            loading={loading}
            onChange={handleTableChange}
            scroll={{x: props.scroll}}
            tableLayout="fixed"
            size="small"
        />
    );
};


export default CustomTable;