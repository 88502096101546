import {CarColumns, Constants} from "./Constants";
import {message} from "antd";
import _ from 'lodash';

const axios = require('axios');

export function checkCookie(setUser) {
    axios.get("/user/logged_in", { withCredentials: true })
        .then(response => {
            setUser(response.data);
        })
        .catch(error => {
            message.error(error)
        })
}

export function login(postData, setUser) {
    axios.post('/user/login', postData
    ).then((value) => {
        if (value.data.error) {
            message.error(Constants.WRONG_USERNAME_OR_PASSWORD);
            return;
        }
        getProfile(value.data[Constants.EMAIL], setUser);
    }).catch(() => {
        message.error(Constants.FAILED_LOGIN);
    });
}


export function logout(setUser) {
    axios.post('/user/logout'
    ).then((value) => {
        if (value.data.error) {
            message.error(value.data.error);
            return;
        }
        setUser(value.data);
    }).catch(() => {
        message.error(Constants.FAILED_LOGOUT);
    });
}


export function getProfile(email, setProfile) {
    axios.get(`/user/${email}`
    ).then((value) => {
        if (value.data.error) {
            message.error(value.data.error);
            return;
        }
        setProfile(value.data);
    }).catch(() => {
        message.error(Constants.FAILED_GET_PROFILE);
    });
}


export function getUsers(setUsers) {
    axios.get("/users"
    ).then((value) => {
        if (value.data.error) {
            message.error(value.data.error);
            return;
        }
        value.data.sort(user => user.username);
        setUsers(value.data);
    }).catch(() => {
        message.error(Constants.FAILED_GET_USERS);
    });
}

export function updateProfile(postData, history, setUser) {
    let email = postData[Constants.EMAIL];
    axios.post(`/user/${email}`, postData, {
        method: 'POST',
        mode: 'no-cors',
        headers: {
            "Content-Type": "application/json",
    }}).then((value) => {
        if (value.data.error) {
            message.error(value.data.error);
            return;
        }

        getProfile(email, (user) => setUser(oldUser => {
            // Change data of current user if the updated profile is the one of the user
            if (user[Constants.EMAIL] === oldUser[Constants.EMAIL]) {
                return user;
            }
            return oldUser;
        }));

        message.success(Constants.SUCCEEDED_UPDATE_PROFILE);
    }).catch(() => {
        message.error(Constants.FAILED_UPDATE_PROFILE);
    });
}

export function createProfile(postData, history) {
    console.log(postData);
    axios.post('/user/signup', postData, {
        method: 'POST',
        mode: 'no-cors',
        headers: {
            "Content-Type": "application/json"
        }
    }).then((value) => {
        if (value.data.error) {
            message.error(value.data.error);
        } else {
            history.goBack()
        }
    }).catch(() => {
        message.error(Constants.FAILED_SIGNUP);
    });
}


export function delete_profile(username, setUsers) {
    axios.delete(`/users/${username}`, {
        method: 'DELETE',
        mode: 'no-cors',
        headers: {
            "Content-Type": "application/json"
        }
    }).then(() => {
        getUsers(setUsers);
    }).catch(() => {
        message.error(Constants.FAILED_DELETE_PROFILE);
    });
}


export function save_filter(name, creatorID, filter, setFilters, setVisible) {
    if (name === "") {
        message.error(Constants.MUST_ENTER_FILENAME);
        return;
    }

    const postData = {
        name: name,
        data: JSON.stringify(filter),
        creator: creatorID
    };

    axios.post("/filters", postData, {
        method: 'POST',
        mode: 'no-cors',
        headers: {
            "Content-Type": "application/json"
        }
    }).then((value) => {
        loadDataFilters(setFilters, creatorID);
        setVisible(false);
    }).catch(() => {
        message.error(Constants.FILTERNAME_EXISTS);
    });
}

export function delete_filter(name, userID, setFilters) {
    axios.delete(`/filters/${name}`, {
        method: 'DELETE',
        mode: 'no-cors',
        headers: {
            "Content-Type": "application/json"
        }
    }).then(() => {
        loadDataFilters(setFilters, userID);
    }).catch(() => {
        message.error(Constants.FAILED_DELETE_FILTER);
    });
}

export function loadDataFilters(setFilters, creatorID) {
    if (!creatorID) return;

    axios.get(`/filters/${creatorID}`, {
        method: 'GET',
        mode: 'no-cors',
        headers: {
            "Content-Type": "application/json"
        },
    }).then((response) => {
        // Filters are stored in database as string
        response.data.forEach(filter => filter.data = JSON.parse(filter.data));
        setFilters(response.data);
    }).catch(() => {
        message.error(Constants.FAILED_LOAD_DATA_FILTERS_FOR_USER);
    });
}


export function loadDataForFilters(setDataForFilters, setEmptyFilter, setLoading) {
    setLoading(true);
    axios.get('/filter_options', {
        method: 'GET',
        mode: 'no-cors',
        headers: {
            "Content-Type": "application/json"
        },
    }).then((response) => {
        setDataForFilters(response.data);
        setEmptyFilter();
        setLoading(false);
    }).catch(e => {
        message.error(Constants.FAILED_LOAD_DATA_FOR_FILTERS);
    });
}

export function loadDataForModelFilter(make, setModelData) {
    axios.post("/filter_options_for_model", {[CarColumns.MAKE]: make || ""}, {
        method: 'GET',
        mode: 'no-cors',
        headers: {
            "Content-Type": "application/json"
        },
    }).then((response) => {
        setModelData(response.data);
    }).catch(() => {
        message.error(Constants.FAILED_LOAD_DATA_FOR_MODEL_FILTER);
    });
}


export function loadDataForTable(filter, params, tableParams, setPagination, setData, setLoading) {
    setLoading(true);
    axios.post('/data_using_filters', {
        results: params.pagination.pageSize,
        page: params.pagination.current,
        ...params,
        tableParams: tableParams,
        filter,
    },{
        method: 'POST',
        mode: 'no-cors',
        headers: {
            "Content-Type": "application/json"
        },
    }).then(response => {
        let data = response.data;

        setPagination({
            ...params.pagination,
            total: data[Constants.TOTAL_COUNT]
        })

        setData(data[Constants.RESULTS]);

        setLoading(false);
    }).catch(() => {
        message.error(Constants.FAILED_LOAD_DATA_FOR_TABLE);
    })
}


export function loadTradersDataForTable(filter, params, setPagination, setData, setLoading) {
    setLoading(true);
    axios.post('/data_traders_using_filters', {
        results: params.pagination.pageSize,
        page: params.pagination.current,
        ...params,
        filter,
    },{
        method: 'POST',
        mode: 'no-cors',
        headers: {
            "Content-Type": "application/json"
        },
    }).then(response => {
        let data = response.data;

        setPagination({
            ...params.pagination,
            total: data[Constants.TOTAL_COUNT]
        })

        setData(data[Constants.RESULTS]);

        setLoading(false);
    }).catch(() => {
        message.error(Constants.FAILED_LOAD_DATA_FOR_TRADERS_TABLE);
    })
}


export function loadDataForSegmentBarChart(filter, params, setData, setLoading) {
    setLoading(true);
    axios.post('/data_for_bar_chart', {
        ...params,
        filter,
    },{
        method: 'POST',
        mode: 'no-cors',
        headers: {
            "Content-Type": "application/json"
        },
    }).then(response => {
        let data = response.data;

        setData(data[Constants.RESULTS]);

        setLoading(false);
    }).catch(() => {
        message.error(Constants.FAILED_LOAD_DATA_FOR_SEGMENT_BARCHART);
    })
}


export function loadDataForPieChart(filter, params, setData, setLoading, percentage=true) {
    setLoading(true);
    axios.post('/data_for_pie_chart', {
        ...params,
        filter,
    },{
        method: 'POST',
        mode: 'no-cors',
        headers: {
            "Content-Type": "application/json"
        },
    }).then(response => {
        let data = response.data;
        data[Constants.RESULTS].forEach(item => {
            if (percentage){
                item["count"] = Math.round(10000 * item.count/data[Constants.TOTAL_COUNT])/100
            }
        })

        setData(data[Constants.RESULTS]);

        setLoading(false);
    }).catch(() => {
        message.error(Constants.FAILED_LOAD_DATA_FOR_PIECHART);
    })
}


export function loadDataForHeatMap(filter, setData, setLoading) {
    setLoading(true);
    axios.post('/data_for_heat_map', {
        filter,
    },{
        method: 'POST',
        mode: 'no-cors',
        headers: {
            "Content-Type": "application/json"
        },
    }).then(response => {
        let data = response.data;

        setData(data[Constants.RESULTS]);

        setLoading(false);
    }).catch(() => {
        message.error(Constants.FAILED_LOAD_DATA_FOR_HEATMAP);
    })
}


export function loadDataForLinesChart(filter, params, setData, setLoading) {
    setLoading(true);
    axios.post('/data_for_lines_chart', {
        ...params,
        filter,
    },{
        method: 'POST',
        mode: 'no-cors',
        headers: {
            "Content-Type": "application/json"
        },
    }).then(response => {
        let data = response.data;

        console.log(data);
        setData(data[Constants.RESULTS]);

        setLoading(false);
    }).catch(() => {
        message.error(Constants.FAILED_LOAD_DATA_FOR_LINES_CHART);
    })
}


export function loadDataForProgressBars(filter, setData, setLoading) {
    setLoading(true);
    axios.post('/data_for_progress_bars', {
        filter,
    },{
        method: 'POST',
        mode: 'no-cors',
        headers: {
            "Content-Type": "application/json"
        },
    }).then(response => {
        let data = response.data;

        setData(data[Constants.RESULTS]);

        setLoading(false);
    }).catch(() => {
        message.error(Constants.FAILED_LOAD_DATA_FOR_PROGRESS_BARS);
    })
}


export function loadFilteredData(filter, method) {
    axios.post('/data_from_filter', {
        filter,
    },{
        method: 'POST',
        mode: 'no-cors',
        headers: {
            "Content-Type": "application/json"
        },
    }).then(response => {
        let data = response.data;

        method(data[Constants.RESULTS]);
    }).catch(() => {
        message.error(Constants.FAILED_LOAD_FILTERED_DATA);
    })
}


export function loadCountFilteredData(filter, setCount) {
    axios.post('/count_cars_from_filter', {
        filter,
    },{
        method: 'POST',
        mode: 'no-cors',
        headers: {
            "Content-Type": "application/json"
        },
    }).then(response => {
        let data = response.data;

        setCount(data[Constants.TOTAL_COUNT]);
    }).catch(() => {
        message.error(Constants.FAILED_LOAD_COUNT_FILTERED_DATA);
    })
}


export default loadDataForFilters;
