import React from 'react';
import {Button, List, Popconfirm, Space} from "antd";
import {DeleteOutlined, UserAddOutlined} from "@ant-design/icons";
import {Link, Redirect} from "react-router-dom";
import {delete_profile} from "../data/data";
import {Constants} from "../data/Constants";
import {getFullName} from "../lib";


const UsersPage = (props) => {

    if (!props.user[Constants.ADMIN]) {
        return <Redirect to="/carsale"/>;
    }

    const deleteUser = (name) => {
        delete_profile(name, props.setUsers);
    }

    return (
        <Space size="large" style={{padding: 20}} direction="vertical">
            <div>
                <div style={{width: "100%", display: "flex"}}>
                    <div style={{width: "50%", display: "flex"}}>
                        <h1>All Users:</h1>
                    </div>
                    <div style={{width: "50%"}}>
                        <div className="collapseAll">
                            <Space direction="horizontal" align="right">
                                <Link to="/user/create"><Button onClick={() => {
                                    return <Link to="/user/create"/>
                                }} type="primary" icon={<UserAddOutlined/>}/>
                                </Link>
                            </Space>
                        </div>
                    </div>
                </div>
            </div>
            <List
                dataSource={props.users}
                renderItem={(value) => (
                    <List.Item
                        key={value.username}
                        actions={[
                            <Popconfirm placement="right" title={Constants.ARE_YOU_SURE} okText={Constants.YES} cancelText={Constants.NO}
                                        onConfirm={() => deleteUser(value.username, props.setUsers)}>
                                <DeleteOutlined/>
                            </Popconfirm>]}
                    >
                        <List.Item.Meta title={<Link to={`/user/${value.email}`}>{getFullName(value)}</Link>}/>
                    </List.Item>
                )}
            >
            </List>
        </Space>
    );
}

export default UsersPage;
