import React, {useEffect, useState} from "react";
import {Layout, Menu} from "antd";
import {loadDataFilters, loadDataForFilters, loadDataForModelFilter} from "../data/data";
import {FilterOutlined, SaveOutlined} from "@ant-design/icons";
import {Constants, DataKeys, FilterKeys} from "../data/Constants";
import Sidebar from "./components/Sidebar";
import SavedFilterSidebar from "./components/SavedFiltersSidebar";
import Body from "./components/Body";
import Sider from "antd/es/layout/Sider";

const {SubMenu} = Menu;
const {Content} = Layout;

const CarsalePage = (props) => {
    const [loading, setLoading] = useState(false);

    const [dataForFilters, setDataForFilters] = useState({
        [DataKeys.MAKES]: [],
        [DataKeys.MODELS]: [],
        [DataKeys.BODY_TYPES]: [],
        [DataKeys.FUELS]: [],
        [DataKeys.REGION]: [],
        [DataKeys.TRADERS]: [],
        [DataKeys.AGE_MIN]: 0, [DataKeys.AGE_MAX]: 0,
        [DataKeys.KM_MIN]: 0, [DataKeys.KM_MAX]: 0,
        [DataKeys.ENGINE_CC_MIN]: 0, [DataKeys.ENGINE_CC_MAX]: 0,
        [DataKeys.DOORS_MIN]: 0, [DataKeys.DOORS_MAX]: 0,
        [DataKeys.POWER_KW_MIN]: 0, [DataKeys.POWER_KW_MAX]: 0,
        [DataKeys.PRICE_MIN]: 0, [DataKeys.PRICE_MAX]: 0
    });

    const [modelData, setModelData] = useState(dataForFilters[DataKeys.MODELS]);

    let emptyFilter = {
        [FilterKeys.MAKE]: undefined,
        [FilterKeys.MODELS]: [],
        [FilterKeys.AGE_RANGE]: [],
        [FilterKeys.SOLD]: undefined,
        [FilterKeys.MILEAGE_RANGE]: [],
        [FilterKeys.BODY_TYPES]: [],
        [FilterKeys.AUTOMATIC]: undefined,
        [FilterKeys.FUEL_TYPES]: [],
        [FilterKeys.ENGINE_CC_RANGE]: [],
        [FilterKeys.DOORS_RANGE]: [],
        [FilterKeys.POWER_KW_RANGE]: [],
        [FilterKeys.PRICE_RANGE]: [],
        [FilterKeys.PROFESSIONAL_TRADER]: true,
        [FilterKeys.OFFICIAL_TRADER]: undefined,
        [FilterKeys.TRADERS]: [],
        [FilterKeys.REGIONS]: [],
    }

    let [savedFilters, setSavedFilters] = useState([]);
    let [filter, setFilter] = useState(null)

    useEffect(() => {
        loadDataFilters(setSavedFilters, props.user.id);
    }, [props.user]);

    useEffect(() => {
        loadDataForFilters(setDataForFilters, () => setFilter(emptyFilter), setLoading);
    }, []);

    useEffect(() => {
        if (filter !== null) {
            loadDataForModelFilter(filter[FilterKeys.MAKE], setModelData);
        }
    }, [filter])

    const setRegionsFilter = (value) => setFilter(previousFilter => ({...previousFilter, [FilterKeys.REGIONS]: value}));
    const setMileageRangeFilter = (value) => setFilter(previousFilter => ({
        ...previousFilter,
        [FilterKeys.MILEAGE_RANGE]: value
    }));
    const setPowerKWRangeFilter = (value) => setFilter(previousFilter => ({
        ...previousFilter,
        [FilterKeys.POWER_KW_RANGE]: value
    }));

    return (
        <Layout hasSider={true}>
            <Sider
                width={280}
                style={{
                    top: "64px",
                    bottom: 0,
                    overflowY: 'scroll',
                    overflowX: "hidden",
                    position: 'fixed',
                    left: 0,
                }}
            >
                <Menu
                    defaultOpenKeys={['sub1']}
                    mode="inline"
                    style={{height: '100%', borderRight: 0}}>
                    <SubMenu key="sub2" icon={<SaveOutlined/>} title={Constants.SAVED_FILTERS}>
                        <SavedFilterSidebar
                            savedFilters={savedFilters} setSavedFilters={setSavedFilters}
                            setFilter={setFilter}
                            user={props.user}
                        />
                    </SubMenu>
                    <SubMenu key="sub1" icon={<FilterOutlined/>} title={Constants.FILTERS}>
                        <Sidebar
                            loading={loading}
                            modelData={modelData}
                            dataForFilters={dataForFilters}
                            filter={filter} setFilter={setFilter}
                            emptyFilter={emptyFilter}
                            setRegionsFilter={setRegionsFilter}
                            setMileageRangeFilter={setMileageRangeFilter}
                            setPowerKWRangeFilter={setPowerKWRangeFilter}
                        />
                    </SubMenu>
                </Menu>
            </Sider>
            <Content>
                {filter !== null ? <Body setFilteredRegion={value => setRegionsFilter([value])}
                                setFilteredMileage={setMileageRangeFilter}
                                filter={filter}
                                user={props.user}
                                setSavedFilters={setSavedFilters}
                                setFilteredPowerKW={setPowerKWRangeFilter}
                            /> : <></>
                }
            </Content>
        </Layout>
    );

}

export default CarsalePage;