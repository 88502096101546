import React, {useEffect, useState} from 'react';
import {Line} from '@ant-design/charts';
import {CarColumns} from "../data/Constants";
import {Spin} from "antd";

const CustomLinesChart = (props) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetch(props.params)
    }, [props.filter])

    const fetch = (params = {}) => {
        props.loadData(setData, setLoading);
    }

    const config = {
        data: data,
        xField: props.columnName,
        yField: props.rowName,
        xAxis: {
            title: {
                text: props.xName
            },
            label: {
                autoRotate: true,
            },
        },
        yAxis: {
            title: {
                text: props.yName
            },
        },
        tooltip: {
            customContent: (title, items) => {
                return (
                    <>
                        <ul style={{ paddingLeft: 0 }}>
                            {items?.map((item, index) => {
                                const { name, value, color } = item;
                                return (
                                    <li
                                        key={item.year}
                                        className="g2-tooltip-list-item"
                                        data-index={index}
                                        style={{ marginBottom: 4, display: 'flex', alignItems: 'center' }}
                                    >
                                        <span className="g2-tooltip-marker" style={{ backgroundColor: color }}></span>
                                        <span
                                            style={{ display: 'inline-flex', flex: 1, justifyContent: 'space-between' }}
                                        >
                                          <span style={{ marginRight: 16 }}>{name}:</span>
                                          <span className="g2-tooltip-list-item-value">{value}</span>
                                        </span>
                                    </li>
                                );
                            })}
                        </ul>
                    </>
                );
            },
        },
        seriesField: props.seriesField,
        autoFit: true,
        color: ['#6b88ff', '#529d27', '#eaa734'],
    };

    return (
        <div style={{width: "100%"}}>
            <Spin spinning={loading}>
                <h2 style={{textAlign: "center"}}>{props.name}</h2>
                <Line {...config} />
            </Spin>
        </div>
    );
};

export default CustomLinesChart;