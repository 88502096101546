import {Select} from "antd";

const {Option} = Select;

const SelectFilterWithFilter = (props) => {

    const children = [];
    props.items.forEach(item => {
        if (item === null)
            item = "/"
        children.push(<Option key={item} value={item}>{item}</Option>)
    })

    return (
        <Select
            value={props.chosen}
            mode={props.mode}
            style={{width: '100%'}}
            showSearch
            allowClear
            placeholder={props.extra}
            onChange={props.onChange}
            filterOption={(input, option) => {
                if (option.children === null) console.log(option);
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
        >
            {children}
        </Select>
    );

}

export default SelectFilterWithFilter;