import {Button, Collapse, Space, Switch} from 'antd';
import React, {useEffect, useState} from 'react';
import {Constants} from "../../data/Constants";
import lib from "../../lib";
import {DownloadOutlined, SaveOutlined} from "@ant-design/icons";
import {loadCountFilteredData, loadFilteredData, save_filter} from "../../data/data";
import AlertDialog from "../../components/AlertDialog";
import CarTables from "../../composedComponents/CarTables";
import RegionAndMap from "../../composedComponents/RegionAndMap";
import TradersAndPieChart from "../../composedComponents/TradersAndPieChart";
import MileageBarChart from "../../composedComponents/MileageBarChart";
import FuelInteriorPowerCharts from "../../composedComponents/FuelInteriorPowerCharts";
import Layout from "antd/es/layout/layout";
import PriceEvolutionChart from "../../composedComponents/PriceEvolutionChart";
import ProgressBars from "../../composedComponents/ProgressBars";

const {Panel} = Collapse;


const Body = (props) => {
    let [activeKeyTables, setActiveKeyTables] = useState(['1']);
    let [activeKeyPriceEvolution, setActiveKeyPriceEvolution] = useState(['1'])
    let [activeKeyCounts, setActiveKeyCounts] = useState(['1']);
    let [activeKeyTraders, setActiveKeyTraders] = useState(['1']);
    let [activeKeyMileage, setActiveKeyMileage] = useState(['1']);
    let [activeKeyComparisons, setActiveKeyComparisons] = useState(['1']);
    let [activeKeyProgressBars, setActiveKeyProgressBars] = useState(['1']);

    const saveFilter = () => {
        save_filter(saveFilterName, props.user.id, props.filter, props.setSavedFilters, setAlertDialogVisible)
    }

    const changeOpen = (bool, toChangeFunction) => {
        if (bool) {
            toChangeFunction(['1']);
        } else {
            toChangeFunction([]);
        }
    }

    let [alertDialogVisible, setAlertDialogVisible] = useState(false);
    let [saveFilterName, setSaveFilterName] = useState("");

    const [count, setCount] = useState();

    useEffect(() => {
        fetch(props.params)
    }, [props.filter])

    const fetch = (params = {}) => {
        if (props.filter !== null) {
            loadCountFilteredData(props.filter, setCount)
        }
    }

    return (
        <Layout style={{marginLeft: 280, padding: 20, height: "100%", overflow: "scroll"}}>
            <Space direction="vertical">
                <div>
                    <div style={{width: "100%", display: "flex"}}>
                        <div style={{width: "50%", display: "flex"}}>
                            <Button onClick={() => setAlertDialogVisible(true)} type="primary" icon={<SaveOutlined/>}>Save
                                Filter</Button>
                            <AlertDialog
                                onClick={saveFilter}
                                title={Constants.SAVE_FILTER}
                                placeholder={Constants.INPUT_FILTERNAME} name={saveFilterName}
                                onChange={object => setSaveFilterName(object.target.value)}
                                visible={alertDialogVisible} setVisible={setAlertDialogVisible}/>
                        </div>
                        <div style={{width: "50%"}}>
                            <div className="collapseAll">
                                <Space direction="horizontal" align="right">
                                    <h1>Collapse all: </h1>
                                    <Switch defaultChecked onChange={value => {
                                        changeOpen(value, setActiveKeyTables);
                                        changeOpen(value, setActiveKeyPriceEvolution);
                                        changeOpen(value, setActiveKeyCounts);
                                        changeOpen(value, setActiveKeyTraders);
                                        changeOpen(value, setActiveKeyMileage);
                                        changeOpen(value, setActiveKeyComparisons);
                                        changeOpen(value, setActiveKeyProgressBars);
                                    }}/>
                                </Space>
                            </div>

                        </div>

                    </div>
                </div>

                <Collapse activeKey={activeKeyTables}
                          onChange={value => changeOpen(value.length !== 0, setActiveKeyTables)}>
                    <Panel header={Constants.ALL_TABLES} key="1">
                        <CarTables officialTraders={props.officialTraders} filter={props.filter}/>
                    </Panel>
                </Collapse>

               <Collapse activeKey={activeKeyPriceEvolution}
                          onChange={value => changeOpen(value.length !== 0, setActiveKeyTables)}>
                    <Panel header={Constants.PRICE_EVOLUTION} key="1">
                        <PriceEvolutionChart filter={props.filter} />
                    </Panel>
                </Collapse>

                <Collapse activeKey={activeKeyProgressBars}
                          onChange={value => changeOpen(value.length !== 0, setActiveKeyProgressBars)}>
                    <Panel header={Constants.OPTIONS_STATISTICS} key="1">
                        <ProgressBars filter={props.filter} />
                    </Panel>
                </Collapse>

                <Collapse activeKey={activeKeyCounts}
                          onChange={value => changeOpen(value.length !== 0, setActiveKeyCounts)}>
                    <Panel header={Constants.REGION_OVERVIEW} key="1">
                        <RegionAndMap
                            filter={props.filter}
                            setFilteredRegion={value => props.setFilteredRegion(value)}/>
                    </Panel>
                </Collapse>

                <Collapse activeKey={activeKeyTraders}
                          onChange={value => changeOpen(value.length !== 0, setActiveKeyTraders)}>
                    <Panel header={Constants.TRADERS_OVERVIEW} key="1">
                        <TradersAndPieChart filter={props.filter}/>
                    </Panel>
                </Collapse>

                <Collapse activeKey={activeKeyMileage}
                          onChange={value => changeOpen(value.length !== 0, setActiveKeyMileage)}>
                    <Panel header={Constants.MILEAGE_BARCHART} key="1">
                        <MileageBarChart
                            filter={props.filter}
                            setFilteredMileage={(min_value, max_value) => props.setFilteredMileage([min_value, max_value])}
                        />
                    </Panel>
                </Collapse>

                <Collapse activeKey={activeKeyComparisons}
                          onChange={value => changeOpen(value.length !== 0, setActiveKeyComparisons)}>
                    <Panel header={Constants.COMPARISONS} key="1">
                        <FuelInteriorPowerCharts
                            filter={props.filter}
                            setFilteredPowerKW={(min_value, max_value) => props.setFilteredPowerKW([min_value, max_value])}
                        />
                    </Panel>
                </Collapse>

                <h1>Download entries, both sold and not sold: {count}</h1>
                <Button className="menuButton"
                        type="primary"
                        onClick={() => loadFilteredData(props.filter, data => lib.createCSVFile(data, lib.getFileNameWithExtension(Constants.FILENAME, "csv")))}>
                    <DownloadOutlined/>
                </Button>

                <br/>
            </Space>
        </Layout>
    );
};

export default Body;
