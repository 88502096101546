import React from 'react';
import {CarColumns} from "../data/Constants";
import {CheckCircleTwoTone} from '@ant-design/icons';
import lib from '../lib';
import {loadDataForTable} from "../data/data";
import CustomTable from "./CustomTable";


const CustomCarTable = (props) => {

    const columns = [
        {
            title: 'Trader',
            dataIndex: CarColumns.TRADER,
            key: CarColumns.TRADER,
            fixed: 'left',
            width: 300,
            sorter: true,
            render: (value => {
                return value || "/";
            })
        },
        {
            title: "Postal code",
            dataIndex: CarColumns.POSTAL_CODE,
            key: CarColumns.POSTAL_CODE,
            align: "center",
            width: 100,
            sorter: true,
        },
        {
            title: "Make Model",
            dataIndex: CarColumns.MAKE_MODEL_URL,
            key: CarColumns.MAKE_MODEL_URL,
            width: 140,
            sorter: true,
            render: (value) => {
                let make_model_url = value.split("$")
                let make_model = make_model_url[0];
                let url = make_model_url.slice(1).join();
                return <a href={url} target="_blank">{make_model || "/"}</a>
            },
        },
        {
            title: "Days On Stock",
            dataIndex: CarColumns.DAYS_ON_STOCK,
            key: CarColumns.DAYS_ON_STOCK,
            align: "right",
            width: 125,
            sorter: true,
        },
        {
            title: "First Registration",
            dataIndex: CarColumns.FIRST_REGISTRATION,
            key: CarColumns.FIRST_REGISTRATION,
            width: 140,
            sorter: true,
        },
        {
            title: "Mileage (km)",
            dataIndex: CarColumns.KM,
            key: CarColumns.KM,
            align: "right",
            width: 115,
            sorter: true,
        },
        {
            title: "Sold",
            dataIndex: CarColumns.SOLD,
            key: CarColumns.SOLD,
            render: (value => {
                if (value) {
                    return <CheckCircleTwoTone twoToneColor="#52c41a"/>
                }
            }),
            align: "center",
            width: 85,
            sorter: true,
        },
        {
            title: "Automatic",
            dataIndex: CarColumns.AUTOMATIC,
            key: CarColumns.AUTOMATIC,
            render: (value => {
                if (value) {
                    return <CheckCircleTwoTone twoToneColor="#52c41a"/>
                }
            }),
            align: "center",
            width: 90,
            sorter: true,
        },
        {
            title: "Power (kw)",
            dataIndex: CarColumns.POWER_KW,
            key: CarColumns.POWER_KW,
            align: "right",
            width: 100,
            sorter: true,
        },
        {
            title: "Fuel",
            dataIndex: CarColumns.FUEL,
            key: CarColumns.FUEL,
            width: 75,
            sorter: true,
        },
        {
            title: "Engine CC",
            dataIndex: CarColumns.ENGINE_CC,
            key: CarColumns.ENGINE_CC,
            align: "right",
            width: 95,
            sorter: true,
        },
        {
            title: "Interior",
            dataIndex: CarColumns.INTERIOR,
            key: CarColumns.INTERIOR,
            width: 125,
            sorter: true,
        },
        {
            title: "Price",
            dataIndex: CarColumns.PRICE,
            key: CarColumns.PRICE,
            render: value => lib.numberWithSeperator(value, '.'),
            align: "right",
            width: 80,
            sorter: true,
        },
    ];

    return (
        <CustomTable
            loadData={(params, setPagination, setData, setLoading) =>
                loadDataForTable(props.filter, params, props.tableParams, setPagination, setData, setLoading)}
            filter={props.filter}
            columns={columns}
            rowKey={CarColumns.URL}
            scroll={1300}
        />
    );
};


export default CustomCarTable;