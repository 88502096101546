import React from "react";
import CustomBarChart from "../customComponents/CustomBarChart";
import {CarColumns, Constants} from "../data/Constants";
import CustomHeatmap from "../customComponents/Heatmap";
import {loadDataForHeatMap, loadDataForPieChart} from "../data/data";

const RegionAndMap = (props) => {

    const params_region = {
        columnName: CarColumns.REGION,
    }

    return (
        <>
            <div className="space">
                <div style={{width: "50%", paddingRight: "10px"}}>
                    <CustomBarChart
                        filter={props.filter}
                        params={params_region}
                        columnName={CarColumns.REGION}
                        rowName={Constants.COUNT}
                        name="Region"
                        loadData={(setData, setLoading) =>
                            loadDataForPieChart(props.filter, params_region, setData, setLoading, false)}
                        setClicked={value => {
                            props.setFilteredRegion(value.region);
                        }}/>
                </div>
                <div style={{width: "50%", paddingLeft: "10px"}}>
                    <CustomHeatmap
                        filter={props.filter}
                        loadData={(setData, setLoading) => {
                            loadDataForHeatMap(props.filter, setData, setLoading)
                        }}
                        name="HeatMap Region"/>
                </div>
            </div>
        </>
    );

}

export default RegionAndMap;