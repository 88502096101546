import {Modal} from "antd";
import React from "react";
import InputBox from "./InputBox";

const AlertDialog = (props) => {

    return (
        <>
            <Modal
                title={props.title}
                centered
                visible={props.visible}
                onOk={() => {
                    props.onClick();
                }}
                onCancel={() => props.setVisible(false)}
            >
                <InputBox placeholder={props.placeholder} onChange={props.onChange}/>
            </Modal>
        </>
    );

}

export default AlertDialog;