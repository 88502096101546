import React, {useEffect, useState} from 'react';
import {Column} from '@ant-design/charts';
import {Constants} from '../data/Constants';
import {Spin} from "antd";

const CustomBarChart = (props) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetch(props.params)
    }, [props.filter])

    const fetch = (params = {}) => {
        props.loadData(setData, setLoading);
    }

    const config = {
        data,
        xField: props.columnName,
        yField: props.rowName,
        label: {
            position: 'middle',
            style: {
                fill: '#FFFFFF',
                opacity: 0.6,
            },
        },
        meta: {
            type: {alias: props.name},
            sales: {alias: Constants.COUNT_CAPITAL},
        },
        autoFit: true,
        xAxis: {label: {autoRotate: true}},
    };

    return (
        <div>
            <Spin spinning={loading}>
                <h2 style={{textAlign: "center"}}>{props.name}</h2>
                <Column {...config} onReady={(plot) => {
                    plot.on('plot:click', (evt) => {
                        const {x, y} = evt;
                        const tooltipData = plot.chart.getTooltipItems({x, y});
                        if (props.hasOwnProperty(Constants.SET_CLICKED)) {
                            props.setClicked(tooltipData[0].data);
                        }
                    });
                }}/>
            </Spin>
        </div>
    );
};


export default CustomBarChart;