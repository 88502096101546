import React, {useEffect, useState} from "react";
import {Layer, Source} from "react-map-gl";


const MAX_ZOOM_LEVEL = 15;
const base = 20;


function heatmapLayer(maxMag) {
    return {
        type: 'heatmap',
        paint: {
            // Increase the heatmap weight based on frequency and property magnitude
            'heatmap-weight': ['interpolate', ['exponential', base], ['get', 'mag'], 0, 0, Math.log(maxMag) / Math.log(base), 0.10],
            // Increase the heatmap color weight weight by zoom level
            // heatmap-intensity is a multiplier on top of heatmap-weight
            'heatmap-intensity': ['interpolate', ['linear'], ['zoom'], 0, 1, MAX_ZOOM_LEVEL, 20],
            // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
            // Begin color ramp at 0-stop with a 0-transparancy color
            // to create a blur-like effect.
            'heatmap-color': [
                'interpolate',
                ['linear'],
                ['heatmap-density'],
                0,
                'rgba(33,102,172,0)',
                0.2,
                'rgb(103,169,207)',
                0.4,
                'rgb(209,229,240)',
                0.6,
                'rgb(253,219,199)',
                0.8,
                'rgb(255,201,101)',
                0.9,
                'rgb(239,138,98)',
            ],
            // Adjust the heatmap radius by zoom level
            'heatmap-radius': ['interpolate', ['linear'], ['zoom'], 0, 2, MAX_ZOOM_LEVEL, 25],
            // Transition from heatmap to circle layer by zoom level
            'heatmap-opacity': 1.0,
        }
    }
}


const HeatmapLayer = (props) => {
    const [layer, setLayer] = useState(heatmapLayer(300));

    useEffect(() => {
        let maximum = Math.max(...(props.data).map(trader => trader.count));
        if (maximum > 0) {
            setLayer(heatmapLayer(maximum));
        }
    }, [props.data])

    const dataGeojson = {
        type: 'FeatureCollection',
        features:
            props.data.map(trader => {
                return {
                    "type": "Feature",
                    "properties": {
                        "mag": trader.count,
                    },
                    "geometry": {
                        "type": "Point",
                        "coordinates": [trader.lon, trader.lat]
                    }
                }
            }),
    }

    return (
        <Source type="geojson" data={dataGeojson}>
            <Layer {...layer} />
        </Source>
    );

}

export default HeatmapLayer;