import React from "react";
import CustomBarChart from "../customComponents/CustomBarChart";
import {CarColumns, Constants} from "../data/Constants";
import {loadDataForSegmentBarChart} from "../data/data";

const MileageBarChart = (props) => {

    const params = {
        columnName: CarColumns.KM,
        segmentSize: Constants.MILEAGE_SEGMENT_SIZE,
        segmentZero: Constants.MILEAGE_SEGMENT_ZERO,
        zero: Constants.MILEAGE_SEGMENT_ZERO_NAME,
        segmentName: CarColumns.MILEAGE_SEGMENT,
    }

    return (
        <>
            <CustomBarChart
                filter={props.filter}
                columnName={Constants.TITLE}
                rowName={Constants.COUNT}
                name="Mileage Segment"
                loadData={(setData, setLoading) => loadDataForSegmentBarChart(props.filter, params, setData, setLoading)}
                setClicked={value => {
                    props.setFilteredMileage(value.min_value, value.max_value);
                }}/>
        </>
    );

}

export default MileageBarChart;