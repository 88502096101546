import React from "react";
import {Button, Form, Input} from "antd";
import {login} from "../data/data";
import {LockOutlined, UserOutlined} from "@ant-design/icons";
import {Constants} from "../data/Constants";


const LoginPage = (props) => {
    return (
        <Form
            name="normal_login"
            className="login-form"
            style={{padding: 20, position: "absolute", width: "50%", left: "25%", top: "25%"}}
            initialValues={{remember: true}}
            onFinish={values => login(values, props.setUser)}
        >
            <Form.Item
                name={Constants.EMAIL}
                rules={[
                    {
                        type: 'email',
                        message: Constants.INVALID_EMAIL,
                    },
                    {
                        required: true,
                        message: Constants.INPUT_EMAIL}
                ]}
            >
                <Input prefix={<UserOutlined className="site-form-item-icon"/>} placeholder="E-mail"/>
            </Form.Item>
            <Form.Item
                name={Constants.PASSWORD}
                rules={[
                    {
                        required: true,
                        message: Constants.INPUT_PASSWORD}
                ]}
            >
                <Input
                    prefix={<LockOutlined className="site-form-item-icon"/>}
                    type="password"
                    placeholder="Password"
                />
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit" className="login-form-button">
                    {Constants.LOG_IN}
                </Button>
            </Form.Item>
        </Form>
    );
}


export default LoginPage;