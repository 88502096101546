import "../../App.css";
import React, { useEffect, useState } from "react";
import {
  checkCookie,
  createProfile,
  getProfile,
  getUsers,
  logout,
  updateProfile,
} from "../../data/data";
import LoginPage from "../LoginPage";
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import CarsalePage from "../CarsalePage";
import UserPage from "../UserPage";
import UsersPage from "../UsersPage";
import { Dropdown, Form, Layout, Menu, message, Space } from "antd";
import { Constants } from "../../data/Constants";
import {
  LogoutOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from "@ant-design/icons";

const { Header } = Layout;

function App() {
  const [form] = Form.useForm();
  let [user, setUser] = useState(Constants.STATE_EMPTY_OBJECT);
  let [users, setUsers] = useState(Constants.STATE_EMPTY_LIST);

  useEffect(() => {
    checkCookie(setUser);
    getUsers(setUsers);
  }, []);

  const menu = () => (
    <Menu>
      <Menu.Item key="1" icon={<UserOutlined />}>
        <Link to={`/user/${user[Constants.EMAIL]}`}>My Profile</Link>
      </Menu.Item>
      {user[Constants.ADMIN] ? (
        <Menu.Item key="2" icon={<UnorderedListOutlined />}>
          <Link to="/users">All Users</Link>
        </Menu.Item>
      ) : undefined}
      <Menu.Item
        key="3"
        icon={<LogoutOutlined />}
        onClick={() => logout(setUser)}
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Router>
      {user?.hasOwnProperty("email") ? (
        <Layout style={{ height: "100%" }}>
          <Header className="header">
            <div className="logo" />

            <div style={{ display: "flex" }} key="user">
              <Menu
                theme="dark"
                mode="horizontal"
                defaultSelectedKeys={[Constants.PORSCHE]}
              >
                <Menu.Item key={Constants.PORSCHE}>
                  <Link to={"/carsale"}>{Constants.PORSCHE}</Link>
                </Menu.Item>
              </Menu>
              <div style={{ position: "fixed", right: "20px" }}>
                <Dropdown overlay={menu}>
                  <Space>
                    {user.username}
                    <UserOutlined
                      style={{ color: "white", right: "20px", left: "10px" }}
                    />
                  </Space>
                </Dropdown>
              </div>
            </div>
          </Header>

          <Switch>
            <Route
              path="/carsale"
              component={() => {
                if (Object.keys(user).length !== 0) {
                  return <CarsalePage user={user} setUser={setUser} />;
                } else {
                  return <></>;
                }
              }}
            />
            <Route
              exact
              path="/user/create"
              component={() => (
                <UserPage
                  user={user}
                  setUser={setUser}
                  newUser={true}
                  btnText={Constants.CREATE}
                  onClick={(values) =>
                    function (history) {
                      if (
                        values[Constants.PASSWORD] !==
                        values[Constants.CONFIRM_PASSWORD]
                      ) {
                        message.error(Constants.PASSWORDS_NOT_EQUAL);
                        return;
                      }
                      delete values[Constants.CONFIRM_PASSWORD];

                      createProfile(values, history);
                      getUsers(setUsers);
                    }
                  }
                  initialize={(email) => {
                    form.resetFields();
                    form.setFieldsValue({ active: true });
                  }}
                  form={form}
                />
              )}
            />
            <Route
              path="/user/:email"
              component={() => (
                <UserPage
                  user={user}
                  setUser={setUser}
                  newUser={false}
                  btnText={Constants.UPDATE}
                  onClick={(values) =>
                    function (history) {
                      if (
                        Constants.PASSWORD in values &&
                        values[Constants.PASSWORD] !==
                          values[Constants.CONFIRM_PASSWORD]
                      ) {
                        message.error(Constants.PASSWORDS_NOT_EQUAL);
                        return;
                      }
                      updateProfile(values, history, setUser);
                      getUsers(setUsers);
                    }
                  }
                  initialize={(email) => {
                    getProfile(email, (value) => {
                      delete value[Constants.PASSWORD];
                      form.resetFields();
                      form.setFieldsValue(value);
                    });
                  }}
                  form={form}
                />
              )}
            />
            <Route
              path="/users"
              component={() => (
                <UsersPage
                  user={user}
                  setUser={setUser}
                  users={users}
                  setUsers={setUsers}
                />
              )}
            />
            <Route
              path="/"
              component={() => <Redirect to={{ pathname: "/carsale" }} />}
            />
          </Switch>
        </Layout>
      ) : (
        <Switch>
          <Route
            path="/login"
            component={() => <LoginPage user={user} setUser={setUser} />}
          />
          <Route
            path="/"
            component={() => <Redirect to={{ pathname: "/login" }} />}
          />
        </Switch>
      )}
    </Router>
  );
}

export default App;
