const DataMethods = {
    FILTERED_CARS: 'filteredCars',
    TRADERS: 'traders',
    OFFICIAL_TRADERS: 'official_traders',
    FILTERS: 'filters',
    FILTERS_BY_CREATOR: 'filtersByCreator',
}

const CarColumns = {
    DATE_DIFF: "date_diff",
    PARTITION: "partition",
    POWER_KW: 'power_kw',
    TRANSMISSION: 'transmission',
    MAKE: 'make',
    MODEL: 'model',
    AGE_MONTHS: 'age_months',
    KM: 'km',
    SOLD: 'sold',
    BODY_TYPE: 'body_type',
    FIRST_SEEN: 'first_seen',
    LAST_SEEN: 'last_seen',
    AUTOMATIC: 'automatic',
    FUEL: 'fuel',
    ENGINE_CC: 'engine_cc',
    DOORS: 'doors',
    POSTAL_CODE: 'postal_code',
    PRICE: 'price',
    TRADER: 'seller_name',
    OFFICIAL_TRADER: 'official_trader',
    TABLE_OFFICIAL_TRADER: 'table_official_trader',
    REGION: 'region',
    COLOR: 'color',
    INTERIOR: 'interior',
    FIRST_REGISTRATION: 'first_registration',
    MAKE_MODEL_URL: 'make_model_url',
    MAKE_MODEL: 'make_model',
    URL: 'url',
    DAYS_ON_STOCK: 'days_on_stock',
    MILEAGE_SEGMENT: 'mileage_segment',
    MILEAGE_SEGMENT_NAME: 'mileage_segment_name',
    MILEAGE_SEGMENT_RANGE: 'mileage_segment_range',
    PROFESSIONAL_TRADER: 'professional_trader',
    TABLE_PROFESSIONAL_TRADER: 'table_professional_trader',
    TRADER_KIND: 'trader_kind',
    POWER_SEGMENT: 'power_segment',
    POWER_SEGMENT_NAME: 'power_segment_name',
    POWER_SEGMENT_RANGE: 'power_segment_range',
    NAME: 'name',
    AIRCONDITIONING: "airconditioning",
    ALLOYWHEELS: "alloywheels",
    GPS: "gps",
    LANE_DEPARTURE: "lane_departure",
    LED: "led",
    OPENROOF: "openroof",
    PANORMAIC: "panormaic",
    PARKASSIST: "parkassist",
    SOUNDSYSTEM: "soundsystem",
    STANDHEAT: "standheat",
    TOWHOOK: "towhook",
    XENON: "xenon",
    COLUMN_TO_NAME: {
        "airconditioning": "Airconditioning",
        "alloywheels": "Alloywheels",
        "gps": "GPS",
        "lane_departure": "Lane Departure",
        "led": "LED",
        "openroof": "Openroof",
        "panormaic": "Panormaic",
        "parkassist": "Parkassist",
        "soundsystem": "Soundsystem",
        "standheat": "Standheat",
        "towhook": "Towhook",
        "xenon": "Xenon",
    }
}


export const TraderColumns = {
    TRADER: 'seller_name',
    COUNT: 'count',
    AGE_MONTHS: 'age_months',
    PRICE: 'price',
}

const FilterKeys = {
    MAKE: 'make',
    MODELS: 'models',
    AGE_RANGE: 'age_range',
    SOLD: 'sold',
    MILEAGE_RANGE: 'mileage_range',
    BODY_TYPES: 'body_types',
    AUTOMATIC: 'automatic',
    FUEL_TYPES: 'fuel_types',
    ENGINE_CC_RANGE: 'engine_cc_range',
    DOORS_RANGE: 'doors_range',
    POWER_KW_RANGE: 'power_kw_range',
    PRICE_RANGE: 'price_range',
    PROFESSIONAL_TRADER: 'professional_trader',
    OFFICIAL_TRADER: 'official_trader',
    TRADERS: 'traders',
    REGIONS: 'regions',
}

const DataKeys = {
    TRANSMISSIONS: 'transmissions',
    MAKES: 'makes',
    MODELS: 'models',
    AGE_MIN: 'age_min',
    AGE_MAX: 'age_max',
    KM_MIN: 'km_min',
    KM_MAX: 'km_max',
    BODY_TYPES: 'body_types',
    FUELS: 'fuels',
    ENGINE_CC_MIN: 'engine_cc_min',
    ENGINE_CC_MAX: 'engine_cc_max',
    DOORS_MIN: 'doors_min',
    DOORS_MAX: 'doors_max',
    SOLD: 'sold',
    AUTOMATIC: 'automatic',
    POWER_KW_MIN: 'power_kw_min',
    POWER_KW_MAX: 'power_kw_max',
    PRICE_MIN: 'price_min',
    PRICE_MAX: 'price_max',
    REGION: 'region',
    TRADERS: 'traders',
}

const Constants = {
    RESULTS: "results",
    TOTAL_COUNT: "totalCount",
    EMPTY_SELECT: "<select>",
    SAVE_FILTER: "Save Filter",
    ALL_TABLES: "Tables official and other traders",
    PRICE_EVOLUTION: "Price evolution",
    OPTIONS_STATISTICS: "Options statistics",
    REGION_OVERVIEW: "Counts in barchart",
    MILEAGE_BARCHART: "Mileage",
    TRADERS_OVERVIEW: "Traders",
    COMPARISONS: "Comparisons",
    FILENAME: "filteredCars",
    PORSCHE: "Porsche",
    NAME: "Name",
    ALL: "All",
    ZERO: 0,
    ONE: 1,
    WIDTH_MINUS: 70,
    MILEAGE_SEGMENT_SIZE: 10000,
    MILEAGE_SEGMENT_ZERO: 100,
    MILEAGE_SEGMENT_ZERO_NAME: "New",
    POWER_SEGMENT_SIZE: 25,
    POWER_SEGMENT_ZERO: 25,
    POWER_SEGMENT_ZERO_NAME: "Zero",
    COUNT: "count",
    COUNT_CAPITAL: "Count",
    TITLE: "title",
    DATA: "data",
    MAX_AGE_MONTHS: 25 * 12,
    MIN_POWER_KW: 25,
    MAX_PRICE: 499000,
    MAX_ENGINE_CC: 6000,
    FILTERS: "Filters",
    SAVED_FILTERS: "Saved Filters",
    WIDTH_SIDEBAR: 280,
    YES_NO: { "Yes": true, "No": false },
    AGE_SOLD: 40,
    INPUT_FILTERNAME: "Filter name",
    MUST_ENTER_FILENAME: 'Must enter filter name.',
    SAVED_FILTER: 'Saved filter.',
    FILTERNAME_EXISTS: 'Filter name exists.',

    FAILED_LOGIN: 'Log in did not succeed',
    FAILED_LOGOUT: 'Log out did not succeed',
    FAILED_SIGNUP: 'Sign up did not succeed',
    FAILED_GET_PROFILE: 'Get profile did not succeed',
    FAILED_GET_USERS: 'Get users did not succeed',
    SUCCEEDED_UPDATE_PROFILE: 'Update profile succeeded',
    FAILED_UPDATE_PROFILE: 'Update profile did not succeed.',
    FAILED_DELETE_PROFILE: 'Delete profile did not succeed.',
    FAILED_DELETE_FILTER: 'Delete filter did not succeed.',
    FAILED_LOAD_DATA_FILTERS_FOR_USER: 'Load filters from user did not succeed.',
    FAILED_LOAD_DATA_FOR_FILTERS: 'Load filters for filters did not succeed.',
    FAILED_LOAD_DATA_FOR_MODEL_FILTER: 'Load filters for model filter did not succeed.',
    FAILED_LOAD_DATA_FOR_TABLE: 'Load data for table did not succeed.',
    FAILED_LOAD_DATA_FOR_TRADERS_TABLE: 'Load data for traders table did not succeed.',
    FAILED_LOAD_DATA_FOR_SEGMENT_BARCHART: 'Load data for segment bar chart did not succeed.',
    FAILED_LOAD_DATA_FOR_PIECHART: 'Load data for pie chart did not succeed.',
    FAILED_LOAD_DATA_FOR_HEATMAP: 'Load data for heatmap did not succeed.',
    FAILED_LOAD_DATA_FOR_LINES_CHART: 'Load data for lines chart did not succeed.',
    FAILED_LOAD_FILTERED_DATA: 'Load filtered data did not succeed.',
    FAILED_LOAD_COUNT_FILTERED_DATA: 'Load count filtered data did not succeed.',
    FAILED_LOAD_DATA_FOR_PROGRESS_BARS: 'Load data for progress bars did not succeed.',

    WRONG_USERNAME_OR_PASSWORD: 'Wrong username or password.',
    INPUT_PASSWORD: 'Please input your Password!',
    INPUT_EMAIL: 'Please input your E-mail!',
    INPUT_ID: 'Please input your Id!',
    INPUT_FIRST_NAME: 'Please input your First Name!',
    INPUT_LAST_NAME: 'Please input your Last Name!',
    INPUT_USERNAME: 'Please input your Username!',
    ARE_YOU_SURE: "Are you sure？",
    ID: "id",
    ADMIN: "admin",
    EMAIL: "email",
    INVALID_EMAIL: 'The input is not valid E-mail!',
    USERNAME: "username",
    FIRST_NAME: "first_name",
    LAST_NAME: "last_name",
    PASSWORD: "password",
    PASSWORDS_NOT_EQUAL: "Passwords are not the same.",
    CONFIRM_PASSWORD: "confirmPassword",
    ACTIVE: "active",
    PAGE_SIZE: 10,
    CREATE: "Create",
    UPDATE: "Update",
    STATE_EMPTY_LIST: [],
    STATE_EMPTY_STRING: "",
    STATE_EMPTY_OBJECT: {},
    FILTER_IS_SET:"Filter is set.",
    YES: "Yes",
    NO: "No",
    SET_CLICKED: "setClicked",
    MAP_STYLE: "mapbox://styles/mapbox/light-v9",
    LOG_IN: "Log in",
}

export { CarColumns, DataKeys, Constants, FilterKeys, DataMethods };