import React from "react";
import {CarColumns} from "../data/Constants";
import {loadDataForLinesChart} from "../data/data";
import CustomLinesChart from "../customComponents/CustomLinesChart";

const PriceEvolutionChart = (props) => {

    const params = {
        columnName: CarColumns.PRICE,
    }

    return (
        <>
            <div className="space">
                <CustomLinesChart
                    loadData={(setData, setLoading) => loadDataForLinesChart(props.filter, params, setData, setLoading)}
                    filter={props.filter}
                    rowName={CarColumns.PRICE}
                    yName={"Price"}
                    xName={"Months ago"}
                    columnName={CarColumns.DATE_DIFF}
                    seriesField={CarColumns.PARTITION}
                    name="Price evolution"
                />
            </div>
        </>
    );

}

export default PriceEvolutionChart;