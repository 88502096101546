import React from 'react';
import {TraderColumns} from "../data/Constants";
import lib from "../lib";
import {loadTradersDataForTable} from "../data/data";
import CustomTable from "./CustomTable";


const CustomTraderTable = (props) => {

    const columns = [
        {
            title: 'Trader',
            dataIndex: TraderColumns.TRADER,
            key: TraderColumns.TRADER,
            width: 150,
            fixed: "left",
            sorter: true,
        },
        {
            title: 'Count',
            dataIndex: TraderColumns.COUNT,
            key: TraderColumns.COUNT,
            align: "right",
            width: 70,
            sorter: true,
        },
        {
            title: 'Average Age Months',
            dataIndex: TraderColumns.AGE_MONTHS,
            key: TraderColumns.AGE_MONTHS,
            align: "right",
            width: 110,
            sorter: true,
        },
        {
            title: 'Average Price',
            dataIndex: TraderColumns.PRICE,
            key: TraderColumns.PRICE,
            render: value => lib.numberWithSeperator(value, '.'),
            align: "right",
            width: 100,
            sorter: true,
        }
    ];

    return (
        <CustomTable
            filter={props.filter}
            loadData={(params, setPagination, setData, setLoading) => {
                loadTradersDataForTable(props.filter, params, setPagination, setData, setLoading)
            }}
            columns={columns}
            rowKey={TraderColumns.TRADER}
            scroll={400}
        />
    );
};


export default CustomTraderTable;