import React, {useEffect, useState} from 'react';
import MapGL from 'react-map-gl';
import HeatmapLayer from "../components/HeatmapLayer";
import {Constants} from "../data/Constants";
import {Spin} from "antd";


const MAPBOX_TOKEN = "pk.eyJ1IjoiYXV0cmFsaXMiLCJhIjoiY2toeXR2emhwMDk0OTJ6cWh0Y3JjeG5kaSJ9.YCvAWa5Wyqof0wPdueve5w";

const Heatmap = (props) => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetch(props.params)
    }, [props.filter])

    const fetch = (params = {}) => {
        props.loadData(setData, setLoading);
    }

    const [dimensions] = useState({
        height: "100%",
        width: "100%"
    });

    let [viewport, setViewport] = useState({
        latitude: 50.5010789,
        longitude: 4.4764595,
        zoom: 6.5,
        height: "100%",
        width: "100%",
    });

    useEffect(() => {
        const handleResize = () => {
            setViewport({
                latitude: 50.5010789,
                longitude: 4.4764595,
                zoom: 6.5,
                height: "100%",
                width: "100%",
            })
        }

        window.addEventListener('resize', handleResize)

        return _ => {
            window.removeEventListener('resize', handleResize)

        }
    }, []);

    return (
        <MapGL
            mapStyle={Constants.MAP_STYLE}
            {...dimensions}
            mapboxApiAccessToken={MAPBOX_TOKEN}
            {...viewport}
            onViewportChange={newView => setViewport(newView)}>
            {data && (
                <Spin spinning={loading}>
                    <HeatmapLayer data={data}/>
                </Spin>
            )}
        </MapGL>
    );

}

export default Heatmap;
