import {Slider} from "antd";
import React, {useEffect, useState} from "react";

const RangeFilterWithFilter = (props) => {

    let [range, setRange] = useState([props.min, props.max]);

    useEffect(() => {
        if (props.chosen?.length === 2) {
            setRange(props.chosen);
        } else {
            setRange([props.min, props.max]);
        }
    }, [props.chosen, props.min, props.max]);

    return (
        <Slider
            value={range}
            step={props.step}
            range={true}
            min={props.min}
            max={props.max}
            defaultValue={[props.min, props.max]}
            onChange={setRange}
            onAfterChange={props.onChange}
        />
    );

}

export default RangeFilterWithFilter;